import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BaseComponent} from './views/base/base.component';
import {ReactiveFormsModule} from "@angular/forms";
import {LoadingComponent} from './views/service-parts/loading/loading.component';
import {OverlayModule} from "@angular/cdk/overlay";
import {
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeModule,
  OwlNativeDateTimeModule
} from '@danielmoncada/angular-datetime-picker';
import {registerLocaleData} from '@angular/common';
import localeJa from '@angular/common/locales/ja';
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {SharedModule} from "./views/common/shared.module";
import {Router} from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";

registerLocaleData(localeJa);

export const NATIVE_DATE_TIME_FORMATS = {
  // ここでのtimezone指定に意味があるのかはっきり分からないが念の為指定
  fullPickerInput: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'ja-JP'
  },
  datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'ja-JP'},
  timePickerInput: {hour: 'numeric', minute: 'numeric', timeZone: 'ja-JP'},
  monthYearLabel: {year: 'numeric', month: 'short', timeZone: 'ja-JP'},
  dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric', timeZone: 'ja-JP'},
  monthYearA11yLabel: {year: 'numeric', month: 'long', timeZone: 'ja-JP'},
};

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    LoadingComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    OverlayModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [
    {
      provide: OWL_DATE_TIME_LOCALE,
      useValue: 'ja-JP'
    },
    {
      provide: OWL_DATE_TIME_FORMATS,
      useValue: NATIVE_DATE_TIME_FORMATS,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule {
}
