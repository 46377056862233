import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from "../../core/services/auth.service";
import {TeamApiService} from "../../core/services/team-api.service";
import {TeamService} from "../../core/services/team.service";
import {OfficialTeam, TeamStatus} from "../../core/model/resources/team";
import {DialogService} from "../../core/services/dialog.service";
import {User} from "../../core/model/user";
import {environment} from "../../../environments/environment";
import {TermOfServiceComponent} from "../common/term-of-service/term-of-service.component";
import {LoginComponent} from "../pages/auth/login/login.component";
import {DropDownMenuService} from "../../core/services/drop-down-menu.service";
import {Subscription} from "rxjs";
import {LoadingService} from "../../core/services/loading.service";
import {MembersApiService} from "../../core/services/members-api.service";
import {TeamMember} from "../../core/model/resources/member";

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit, OnDestroy {
  @ViewChild('dropDownMenu') dropDownMenuTemplate?: TemplateRef<Component>;

  isDev: boolean = environment.envName === 'dev';
  teamId: number = Number(this.route.snapshot.paramMap.get('teamId'));

  user: User | null = null;
  team: OfficialTeam | null = null;
  teamServiceSubscription?: Subscription;
  accountName: string = '';
  linkedMember: TeamMember | null = null;
  teamName: string = ''
  isOpenSideNav = true;
  currentYear = new Date().getFullYear();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private vcRef: ViewContainerRef,
    private authService: AuthService,
    private teamApiService: TeamApiService,
    private teamService: TeamService,
    private membersApiService: MembersApiService,
    private dropDownMenuService: DropDownMenuService,
    private dialogService: DialogService,
    private loadingService: LoadingService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.user = await this.authService.getUser();
    this.team = this.teamService.getTeam() || await this.teamApiService.getTeam(this.teamId);
    if (this.team === null) {
      await this.dialogService.alert({
        title: 'エラー',
        content: 'チーム情報の取得に失敗しました。リロードしても解決しない場合はネットワーク接続をご確認ください'
      });
      return;
    }
    const relatedAccounts = await this.teamApiService.getRelatedAccounts(this.team.id);
    this.authService.setRelatedAccounts(relatedAccounts);

    this.teamService.setTeam(this.team);
    await this.setDefaultValue(this.team);

    this.teamServiceSubscription = this.teamService.execChange.subscribe(async (team: OfficialTeam) => {
      this.team = team;
      await this.setDefaultValue(this.team);
    });
  }

  ngOnDestroy(): void {
    if (this.teamServiceSubscription) {
      this.teamServiceSubscription.unsubscribe();
    }
  }

  isTattaAdminUser(): boolean {
    return this.authService.isTattaAdminUser();
  }

  isContractor(): boolean {
    if (!this.team) {
      return false;
    }
    return this.authService.isContractor(this.team);
  }

  isAdministrator(): boolean {
    if (!this.team) {
      return false;
    }
    return this.authService.isAdministrator(this.team);
  }

  isAmbassador(): boolean {
    if (!this.team) {
      return false;
    }
    return this.authService.isAmbassador(this.team);
  }

  async setDefaultValue(team: OfficialTeam): Promise<void> {
    if (this.authService.user) {
      this.accountName = this.authService.user.name;
    }
    const linkUserId = this.authService.getLinkedUserId(team);
    this.linkedMember = linkUserId !== undefined ? await this.membersApiService.getTeamMember(team.id, linkUserId) : null;
    this.teamName = team.name;
  }

  toggleSideNav(): void {
    this.isOpenSideNav = !this.isOpenSideNav;
  }

  openSideNav(): void {
    if (!this.isOpenSideNav) {
      this.toggleSideNav();
    }
  }

  closeSideNav(): void {
    if (this.isOpenSideNav) {
      this.toggleSideNav();
    }
  }

  async returnHome(): Promise<void> {
    await this.router.navigate([`${this.teamId}/licence`]);
    this.openSideNav();
  }

  toggleDropDownMenu(origin: ElementRef) {
    this.dropDownMenuService.toggle(origin, this.dropDownMenuTemplate!, this.vcRef);
  }

  async logout(): Promise<void> {
    this.dropDownMenuService.hide();
    const retDialog = await this.dialogService.confirm({content: 'ログアウトしますか？'});
    if (!retDialog) {
      return;
    }

    const ret: boolean = await this.authService.logout();
    if (ret) {
      await this.router.navigateByUrl('/auth/login');
    }
  }

  async openTermOfService(): Promise<void> {
    await this.dialogService.customComponent(TermOfServiceComponent, {withAgreeOption: false});
  }

  hasManyTeams(): boolean {
    if (!this.authService.user?.teamAuthorization) {
      return false;
    }
    return this.authService.user.teamAuthorization.adminTeamIds.length + this.authService.user.teamAuthorization.ambassadorTeamIds.length >= 2;
  }

  async changeTeam(): Promise<void> {
    this.dropDownMenuService.hide();
    const team = await LoginComponent.getTeam(this.teamApiService, this.dialogService, this.loadingService, this.authService.user?.teamAuthorization, true);
    if (team) {
      this.team = team;
      this.teamId = team.id;
      this.teamService.setTeam(team);
      const relatedAccounts = await this.teamApiService.getRelatedAccounts(this.team.id);
      this.authService.setRelatedAccounts(relatedAccounts);
      await this.setDefaultValue(this.team);
      await this.router.navigate([`${team.id}/licence`]);
    }
  }

  isTeamStatusDraft(): boolean {
    return this.team?.status === TeamStatus.draft;
  }
}
