import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BaseComponent} from "./views/base/base.component";
import {AuthGuard} from "./core/auth/guards/auth.guard";

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)
  },
  // TATTA Adminから遷移してきた際のパス設定
  {
    path: 'teams/:teamId',
    redirectTo: 'auth/login/:teamId',
    pathMatch: 'full',
  },
  {
    path: 'developer',
    loadChildren: () => import('./views/pages/developer/developer.module').then(m => m.DeveloperModule)
  },
  {
    path: ':teamId',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {path: 'licence', loadChildren: () => import('./views/pages/licence/licence.module').then(m => m.LicenceModule)},
      {path: 'team', loadChildren: () => import('./views/pages/team/team.module').then(m => m.TeamModule)},
      {
        path: 'member',
        loadChildren: () => import('./views/pages/member/member.module').then(m => m.MemberModule)
      },
      {
        path: 'invitation',
        loadChildren: () => import('./views/pages/invitation/invitation.module').then(m => m.InvitationModule)
      },
      {path: 'event', loadChildren: () => import('./views/pages/event/event.module').then(m => m.EventModule)},
      {
        path: 'live-track',
        loadChildren: () => import('./views/pages/live-track/live-track.module').then(m => m.LiveTrackModule)
      },
      {path: 'feed', loadChildren: () => import('./views/pages/feed/feed.module').then(m => m.FeedModule)},
      {path: 'course', loadChildren: () => import('./views/pages/course/course.module').then(m => m.CourseModule)},
      {path: 'coupon', loadChildren: () => import('./views/pages/coupon/coupon.module').then(m => m.CouponModule)},
    ],
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
