<header class="header" [ngClass]="{'dev': isDev}">
  <h1 class="title">
    <a class="title-link" (click)="returnHome()">
      <img src="/assets/logo-tatta-team-admin.svg" alt="TATTA Team Admin" height="26"/>
      <span *ngIf="isDev" class="dev-sub-title">試験環境</span>
    </a>
  </h1>
  <ul class="header-menu-list">
    <!--  TODO: ヘルプ実装  -->
    <!--    <li><a href="#">ヘルプ</a></li>-->
    <ng-container *ngIf="team">
      <li *ngIf="isTattaAdminUser()"><p class="label-account">TATTA Adminアカウント</p></li>
      <li *ngIf="isContractor()"><p class="label-account">契約者</p></li>
      <li *ngIf="isAdministrator()"><p class="label-account">管理者</p></li>
      <li *ngIf="isAmbassador()"><p class="label-account">アンバサダー</p></li>
    </ng-container>
    <li>
      <button #origin="cdkOverlayOrigin" cdk-overlay-origin class="btn-menu"
              (click)="toggleDropDownMenu(origin.elementRef)">menu
      </button>
      <ng-template #dropDownMenu>
        <ul class="common-drop-down-menu-list">
          <li *ngIf="hasManyTeams()"><a (click)="changeTeam()">チーム選択</a></li>
          <li><a (click)="logout()">ログアウト</a></li>
        </ul>
      </ng-template>
    </li>
  </ul>
</header>
<div class="container">
  <nav class="side-nav" [ngClass]="{'isOpenSideNav': isOpenSideNav}">
    <button class="btn-sidenav-toggle" [ngClass]="{'isOpenSideNav': isOpenSideNav}" (click)="toggleSideNav()">&nbsp;
    </button>
    <div class="side-nav-inner">
      <div *ngIf="linkedMember" class="link-member-container">
        <app-profile-icon [src]="linkedMember.smallPictureUrl"></app-profile-icon>
        <p class="link-member-nickname">{{linkedMember.nickname}}</p>
      </div>
      <p *ngIf="!linkedMember" class="account-name">{{accountName}}</p>
      <p class="team-name">{{teamName}}</p>
      <ul class="side-nav-list">
        <li><a class="nav-licence" routerLinkActive="active" routerLink="/{{teamId}}/licence">ライセンス管理</a></li>
        <li *ngIf="!isAmbassador()"><a class="nav-team" routerLinkActive="active" routerLink="/{{teamId}}/team">チーム管理</a></li>
        <li *ngIf="!isTeamStatusDraft() && !isAmbassador()"><a class="nav-member" routerLinkActive="active" routerLink="/{{teamId}}/member">メンバー管理</a></li>
        <li *ngIf="!isTeamStatusDraft() && !isAmbassador()"><a class="nav-invitation" routerLinkActive="active" routerLink="/{{teamId}}/invitation">招待</a></li>
        <li *ngIf="!isTeamStatusDraft()"><a class="nav-event" routerLinkActive="active" routerLink="/{{teamId}}/event">イベント</a></li>
        <li *ngIf="!isTeamStatusDraft()"><a class="nav-live-track" routerLinkActive="active" routerLink="/{{teamId}}/live-track">ライブトラック</a></li>
        <li *ngIf="!isTeamStatusDraft()"><a class="nav-home-course" routerLinkActive="active" routerLink="/{{teamId}}/course">ホームコース</a></li>
        <li *ngIf="!isTeamStatusDraft()"><a class="nav-feed" routerLinkActive="active" routerLink="/{{teamId}}/feed">掲示板</a></li>
        <!-- TODO: チームに所属しているだけで配布可能なクーポンメニューリンク（未実装） -->
<!--        <li *ngIf="!isTeamStatusDraft()"><a class="nav-feed" routerLinkActive="active" routerLink="/{{teamId}}/coupon">チーム内クーポン</a></li>-->
      </ul>
      <aside class="aside">
        <p><img src="/assets/logo-rbies.svg" alt="R-bies Co., Ltd." height="20"></p>
        <ul class="service-info-list">
          <li><a href="https://runners.co.jp/" target="_blank">運営会社</a></li>
          <li><a (click)="openTermOfService()">利用規約</a></li>
          <li><a href="https://runners.co.jp/privacy/" target="_blank">プライバシーポリシー</a></li>
          <!--  TODO: お問い合わせリンクの宛先検討しつつ追加 -->
          <!--  <li><a href="https://runnet.jp/help/contact/mailConfirmInquiryAction.do?command=mail&&categoryCode1=01&&categoryCode2=02" target="_blank">お問い合わせ</a></li>-->
        </ul>
        <p class="copyright">&copy; 2016-{{currentYear}} R-bies Co., Ltd.</p>
      </aside>
    </div>
  </nav>
  <main class="main-content" [ngClass]="{'isOpenSideNav': isOpenSideNav}">
    <router-outlet></router-outlet>
  </main>
</div>
