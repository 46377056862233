import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from "../../services/auth.service";
import {TeamService} from "../../services/team.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  urlTeamId?: number;

  constructor(
    private router: Router,
    private authService: AuthService,
    private teamService: TeamService
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    this.urlTeamId = Number(route.paramMap.get('teamId'));
    if ((await this.authService.isLoggedIn()) && this.authService.isValidateUrlTeamId(this.urlTeamId)) {
      const team = this.teamService.getTeam();
      if (team && this.authService.isAmbassador(team) && (
        state.url.includes('team') ||
        state.url.includes('member') ||
        state.url.includes('invitation')
      )) {
        await this.authService.logout();
        await this.router.navigate([`/auth/login`]);
        return false;
      }
      return true;
    } else {
      await this.authService.logout();
      await this.router.navigate([`/auth/login`]);
      return false;
    }
  }

}
