import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {Amplify} from 'aws-amplify';
import * as Sentry from "@sentry/angular-ivy";

// manual config
// https://aws-amplify.github.io/docs/js/authentication
Amplify.configure(environment.amplify);

if (environment.production) {
  enableProdMode();

  // Sentry set up
  // https://docs.sentry.io/platforms/javascript/guides/angular/usage/
  Sentry.init({
    environment: environment.envName,
    dsn: environment.sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration()
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
